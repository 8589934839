/* You can add global styles to this file, and also import other style files */
/* src/styles.css */

@import 'tailwindcss/utilities';
@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Your custom styles go here */
// @import url("assets/fonts/STCForward/style-sheet.scss");
@import './assets/fonts/STCForward/style-sheet.scss';
html,body {
    font-family: "STCForward-Regular"  !important;
    box-sizing: border-box;
    margin: 0px ;
    padding: 0px;
    overflow-x: hidden;
 }

div{
  line-height: normal;
}

 .center-text__bg {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -55%);
    top: 30%;
    color: white;
    width: 100%;
    padding: 0 16px;
    text-align: center;
}
.stc-logo__title {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}
.stc-menu__title {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
}
.footer__title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
.stc-title__aboutus {
    color: #2C333B;
    font-weight: 500;
    text-align: center;
    padding: 40px 0;
    max-width: 65rem;
    margin: auto;
}
.main-title__section {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    color: #4F008C;
    margin-block: 24px;
}
.main-subtitle__section {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #4F008C;
    margin-bottom: 32px;
}
.main-title__timeline {
    font-size: 52px !important;
}
.title-contactus {
    position: absolute;
    top: 5.5rem;
    width: 35rem;
    letter-spacing: 2px;
    line-height: 1.2;
    color: #fff;
}

.timeline {
    display: flex;
    justify-content: space-around;
  }

  .timeline-item {
    position: relative;
    width: 170px;
    margin: 20px;
  }

  .timeline-content {
    h3 {
      margin-top: 0;
    }
  }

  .stc-btn {
    padding: 16px;
    border-radius: 30px;
    min-width: 160px;
    cursor: pointer;
  }
  .stc-btn-coral {
    background: #FF375E;
    color: white;
  }
  .truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .break-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }




  /* fullscreen-menu.component.scss */

.hamburger-menu {
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1000;

    .bar {
      width: 30px;
      height: 3px;
      background-color: #333;
      margin: 6px 0;
      transition: 0.4s;
    }
  }

  .fullscreen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('assets/images/menu-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center ;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 999;
    transform: translateX(-100%);
    transition: transform 0.4s ease-in-out;

      .close-button {
        position: absolute;
        top: 22px;
        right: 42px;
        cursor: pointer;
        width: 30px;
      }
    ul {
      list-style: none;
      padding: 0;
      text-align: start;
      .fullscreen-menu__item {

        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }

  .main-content {
    padding: 20px;
  }
  .menu-opened  {
    transform: translateX(0);
  }
